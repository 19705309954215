<template>
  <div
    class="
      page-wrapper
      mx-auto
      md:m3 md:w-11/12
      bg-white
      md:rounded-2xl
      shadow-xl
      p-3
      min-h-screen
      flex flex-col
      justify-start
    "
  >
    <Header @print="print" />
    <main class="main" id="print">
      <div class="content-body">
        <router-view></router-view>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "../components/footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  methods: {
    print() {
      // window.print();
      var canvas;
      if (document.getElementById("chart-all"))
        canvas = document.getElementById("chart-all");
      if (document.getElementById("chart-income"))
        canvas = document.getElementById("chart-income");
      if (document.getElementById("chart-expense"))
        canvas = document.getElementById("chart-expense");

      var img = canvas ? canvas.toDataURL("image/png") : "";
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);
      WinPrint.document.write('<img src="' + img + '"/>');
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    },
  },
};
</script>
