<template>
  <div
    id="userinfo"
    ref="userinfo"
    class="
      userinfo
      bg-gray-200
      p-3
      text-center
      mb-1
      flex
      justify-between
      print-display
    "
  >
    <router-link to="/dashboard/setting/profile" class="flex space-x-4">
      <span>{{ user.username }}</span>
      <CogIcon class="h-6 w-6" />
    </router-link>

    <span class="font-bold uppercase print-display">
      <button type="button" @click="logout">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          />
        </svg>
      </button>
    </span>
  </div>

  <div class="header pt-6 p-4 pb-0 bg-secondary page">
    <div
      class="
        text-4xl
        lg:text-8xl
        font-bold
        text-primary
        print-display
        animate__animated animate__fadeInDown
      "
    >
      <span class="font-spartan text-7xl md:text-9xl font-light">€</span
      >{{ $i18n.locale == "de" ? "Haushaltsbuch" : "Il bilancio in famiglia" }}
    </div>
  </div>
  <div
    class="
      print-display
      menu-bar-top
      flex flex-wrap
      justify-between
      place-items-center
      p-3
      text-primary
      mt-3
      mb-5
      relative
      overflow-hidden
    "
  >
    <datepicker
      style="width: 9rem"
      class="
        order-1
        border-none
        text-xl text-center
        date-input
        text-secondary
        rounded-md
        py-0
      "
      :placeholder="'Month'"
      :inputFormat="'dd.MM.yyyy'"
      :minimumView="'month'"
      :maximumView="'year'"
      :initialView="'year'"
      :locale="lan"
      :disabled="true"
      v-model="currentDate"
    ></datepicker>
    <div
      @click="showMenu = !showMenu"
      class="
        menuicon
        block
        lg:hidden
        xs:absolute
        z-50
        xs:left-1/2 xs:transform xs:-translate-x-1/2
        overflow-hidden
        xs:top-6
        relative
        order-3
      "
    >
      <svg
        class="showmenu text-primary mx-auto"
        :class="showMenu ? 'hidden' : 'block'"
        width="25"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style="enable-background: new 0 0 512 512"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              class="fill-current"
              d="M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="fill-current"
              d="M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="fill-current"
              d="M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20
			C512,404.954,503.046,396,492,396z"
            />
          </g>
        </g>
      </svg>
      <svg
        class="hidemenu text-primary mx-auto"
        :class="showMenu ? 'block' : 'hidden'"
        width="20"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512.001 512.001"
        style="enable-background: new 0 0 512.001 512.001"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              class="fill-current"
              d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
			L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
			c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
			l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
			L284.286,256.002z"
            />
          </g>
        </g>
      </svg>
    </div>

    <nav
      class="page-main-nav order-3 w-full text-center mt-4"
      :class="showMenu ? 'block' : 'hidden lg:block'"
    >
      <!-- Menu verschwindet auf mobilen. wird durch dropdown ersetzt mit dem Hamburgerbutton darober -->
      <ul
        class="
          nav
          inline-block
          mx-auto
          bg-primary
          lg:bg-transparent
          py-4
          lg:py-0
        "
      >
        <li
          class="
            lg:flex lg:space-x-1
            px-4
            space-y-4
            lg:space-y-2 lg:p-0
            flex-wrap
            justify-center
          "
        >
          <router-link
            @click="showMenu = !showMenu"
            v-for="item in navigation"
            :key="item.name"
            class="
              mybtn
              border-primary
              lg:border-2
              md:text-base
              lg:hover:text-white lg:hover:bg-primary lg:hover:border-secondary
              text-center
              block
              lg:flex
              items-center
              text-white
              lg:text-primary
              mt-1
            "
            active-class="active bg-secondary lg:bg-transparent"
            :to="item.href"
            ><span>{{ $t(item.name) }}</span></router-link
          >
          <button
            @click="openNew"
            class="
              mybtn
              border-primary
              lg:border-2
              md:text-base
              lg:hover:text-white lg:hover:bg-primary lg:hover:border-secondary
              text-center
              block
              lg:flex
              items-center
              text-white
              lg:text-primary
              w-full
              lg:w-auto
            "
          >
            {{ $t("what is new?") }}
          </button>
        </li>
      </ul>
    </nav>

    <div class="flex space-x-1 order-2 z-10">
      <button @click="help" type="button" class="mybtn hover border-primary">
        <span
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>
      </button>
      <button @click="print" type="button" class="mybtn hover border-primary">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
            />
          </svg>
        </span>
      </button>
    </div>
  </div>
  <div v-if="emailVerify" class="justify-center flex">
    <div
      class="
        bg-primary
        fixed
        max-w-4xl
        z-50
        shadow-2xl
        px-10
        py-5
        text-center
        rounded-lg
        bottom-24
        sm:bottom-auto
      "
    >
      <span class="font-bold">{{ $t("Important NOTE") }}</span>
      <p>
        {{ $t("Bitte geben Sie unter") }}
        <a
          class="underline cursor-pointer text-blue-500"
          @click="goToSetting"
          >{{ $t("setting") }}</a
        >
        {{ $t("Ihre E-Mailadresse ein") }}
        <span class="mt-2" v-html="$t('QUESTION_VERIFICATION')"></span>
      </p>

      <div
        class="
          px-auto
          sm:px-36
          mt-5
          flex flex-col
          justify-between
          items-center
          space-x-0
          sm:space-x-4 sm:flex-row
        "
      >
        <button
          @click="understand"
          class="bg-secondary text-white rounded-lg px-6 py-2 w-full"
        >
          {{ $t("understand") }}
        </button>
        <button
          @click="emailVerify = false"
          class="
            bg-secondary
            text-white
            rounded-lg
            px-6
            py-2
            w-full
            mt-3
            sm:mt-0
          "
        >
          {{ $t("remind me later") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vue3-datepicker";
import { de, it } from "date-fns/locale";
import { Printd } from "printd";

import { CogIcon } from "@heroicons/vue/outline";

const navigation = [
  { name: "Movement", href: "/dashboard/movement" },
  { name: "Transfer Movement", href: "/dashboard/transfer" },
  { name: "Planning", href: "/dashboard/planning" },
  { name: "Transfer Planning", href: "/dashboard/transferplanning" },
  { name: "Evaluation", href: "/dashboard/evaluation" },
  { name: "Forecast", href: "/dashboard/forecast" },
  // { name: "Note", href: "/dashboard/note" },

  { name: "setting", href: "/dashboard/setting" },
];

export default {
  components: {
    Datepicker,
    CogIcon,
  },
  data() {
    return {
      currentDate: new Date(),
      lan: this.$i18n.locale == "de" ? de : it,
      navigation,
      showMenu: false,
      emailVerify: false,
    };
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    logout() {
      localStorage.removeItem("forecastTime");
      this.$store.commit("User/clearAuthData");
      this.$router.push("/");
    },
    print() {
      const css = `@media print {
  .print-display {
    display: none !important;
  }
  .print {
    display: flex !important;
    flex-direction: column !important;
  }
  .print-table {
    width: 100% !important;
  }
  .anfang{
     border: 2px solid !important;
     width: 100% !important;
     margin-top: 4px !important;
  }
  .print-text-center-border{
    text-align: center !important;
     border: 2px solid !important;
  }
  .print-text-center{
    text-align: center !important;
  }
  .print-flex{
    display: flex !important;
    width: 100% !important;
  }
  .print-flex-col{
    flex-direction: column !important;
  } 
  .print-flex-row{
    flex-direction: row !important;
  }
  .print-justify-between{
    justify-content: space-between !important;

  }
  .print-border{
    border: 2px solid !important;
  }
  .print-mt-1{
    margin-top: 0.3rem !important;
  }
  .print-mt-3{
    margin-top: 1rem !important;
  }
  .print-text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
    font-weight: 600 !important;
  }
  .print-w-4{
    width: 33.333333% !important;
  }
  .print-w-2{
    width: 16.666667% !important;
  }
  .print-w-3{
    width: 25% !important;
  }
  .print-w-full{
    width: 100% !important;
  }
  .print-text-right{
    text-align: right !important;

  }
  .print-font-bold{
    font-weight: 700 !important;
  }
  .print-border-top{
     border-top: 5px solid !important;

  }

}`;
      const d = new Printd();
      d.print(document.getElementById("printMe"), [css]);
    },
    help() {
      window.open(
        localStorage.getItem("haushaltsLang") == "de"
          ? "https://www.consumer.bz.it/de/hb-hilfe"
          : "https://www.consumer.bz.it/it/bf-aiuto"
      );
    },
    openNew() {
      if (localStorage.getItem("haushaltsLang")) {
        window.open(
          localStorage.getItem("haushaltsLang") == "de"
            ? "https://www.consumer.bz.it/de/hb-was-ist-neu"
            : "https://www.consumer.bz.it/it/bf-le-novita"
        );
      } else {
        window.open("https://www.consumer.bz.it/de/hb-was-ist-neu");
      }
    },
    understand() {
      localStorage.setItem(`verify${this.user.id}`, "false");
      this.emailVerify = false;
    },
    goToSetting() {
      this.$router.push("setting/profile");
      this.emailVerify = false;
    },
  },
  computed: {
    user() {
      return this.$store.state.User.user;
    },
  },
  mounted() {
    this.$store.dispatch("User/fetchUser").then(() => {
      if (localStorage.getItem(`verify${this.user.id}`) !== "false") {
        if (this.user.email_verified && this.user.email_verified == false) {
          this.emailVerify = true;
        }
        if (!this.user.email_verified) {
          if (
            (this.user.email_verified_at &&
              this.user.email_verified_at == null) ||
            this.user.email_verified_at == undefined
          ) {
            this.emailVerify = true;
          }
        }
      }
    });
  },
};
</script>

<style>
.font-spartan {
  font-family: Spartan;
}
.mybtn {
  cursor: pointer;
  border-radius: 3333px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
@media (min-width: 1024px) {
  .active {
    --tw-border-opacity: 1;
    border-color: rgba(150, 61, 151, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(202, 158, 203, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media print {
  .print-display {
    display: none !important;
  }
}
</style>
